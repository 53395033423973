import * as Sentry from "@sentry/vue"
import dayjs from "dayjs"

export default defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig()
  const { COMMIT_SHA, COMMIT_TIMESTAMP, ENVIRONMENT_TIER } = config.public

  const dsn = config.public.SENTRY_DSN

  const date = COMMIT_TIMESTAMP ? dayjs(COMMIT_TIMESTAMP) : dayjs()
  const versionString = `${date.format("YY.M")}.${COMMIT_SHA}`

  const release = `SAM-Front@${versionString}`

  const ignoreErrors: Array<string> | undefined = process.env.ENVIRONMENT_TIER === "production" ? undefined : ["File not found"]

  Sentry.init({
    dsn,
    release,
    environment: ENVIRONMENT_TIER,
    enabled: ENVIRONMENT_TIER !== "local",
    app: vueApp,
    ignoreErrors,
    integrations: [
      Sentry.browserTracingIntegration(),
      // only enable replays for production
      ...(ENVIRONMENT_TIER === "production"
        ? [
            Sentry.replayIntegration({
              networkDetailAllowUrls: [
                "/actions",
                "/malfunctions",
                "/inspections",
                "/tickets",
                "/assets",
                "/openfga",
                "/organizations",
                "/measurements",
              ],
            }),
          ]
        : []),
    ],
    sampleRate: 1,
    tracesSampleRate: 1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
})
