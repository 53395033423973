import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import { md, aliases } from "vuetify/iconsets/md"

const customIcons = {
  asset: "dns",
  assetTag: "tag",
  assetCondition: "assignment_turned_in",
  assetTemplates: "bookmarks",
  assetTemplate: "bookmark",
  assetStructure: "account_tree",
  lookupType: "list",
  lookupValue: "list",
  componentType: "category",
  listValue: "list",

  risk: "report",
  finance: "euro",
  dataQuality: "data_usage",

  shared: "share",

  ticket: "description",
  inspection: "task_alt",

  organization: "account_balance",
  customer: "account_balance",
  supplier: "group",
  label: "label",

  domain_pumping_stations: "water_drop",
  domain_civil: "home_repair_service",

  users: "groups",
  user: "person",

  releaseNotes: "new_releases",
  scripts: "code",
  securityLogs: "security",
  mutationLogs: "manage_history",

  adminPanel: "admin_panel_settings",
}

const customColors = {
  customer: "#3F51B5",
  supplier: "#2196F3",
  label: "#212121",

  domain_pumping_stations: "#2196F3",
  domain_civil: "#757575",

  admin: "#9C27B0",
}

export default defineNuxtPlugin(({ vueApp }) => {
  const vuetify = createVuetify({
    ssr: true,
    components: {
      ...components,
    },
    directives,

    icons: {
      defaultSet: "md",
      aliases: {
        ...aliases,
        ...customIcons,
      },
      sets: { md },
    },

    theme: {
      defaultTheme: "light",
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#006ca9",
            secondary: "#009cd1",
            tertiary: "#002440",
            background: "#f5f5f5",
            error: "#C10015",
            success: "#21BA45",
            info: "#31CCEC",
            warning: "#F0AD4E",
            ...customColors,
          },
        },
        dark: {
          dark: true,
          colors: {
            primary: "#00A8FF",
            secondary: "#008DBF",
            tertiary: "#002440",
            background: "#0d1117",
            surface: "#21262d",
            error: "#DC143C",
            success: "#21BA45",
            info: "#1BA6C8",
            warning: "#F0AD4E",
            ...customColors,
            admin: "#D500F9",
          },
        },
        darkPlus: {
          dark: true,
          colors: {
            primary: "#3399FF",
            secondary: "#1E90FF",
            tertiary: "#282C34",
            background: "#1C1C1C",
            surface: "#2C2C2C",
            error: "#FF4C4C",
            success: "#28A745",
            info: "#17A2B8",
            warning: "#FFC107",
            ...customColors,
          },
        },
        civilLight: {
          dark: false,
          colors: {
            primary: "#54494B", // Primary elements
            secondary: "#B1A9A1", // Secondary elements
            tertiary: "#3C3F45", // Highlights or emphasis
            background: "#FBF8FA", // Slightly lighter for background
            surface: "#F0EBE7", // Slightly lighter for surface
            error: "#D32F2F", // Softer error color
            success: "#388E3C", // Softer success color
            info: "#1976D2", // Softer info color
            warning: "#FFA000", // Softer warning color
            ...customColors,
          },
        },
        purple: {
          dark: false,
          colors: {
            primary: "#6A4C93", // Rich purple for primary elements
            secondary: "#CBBAC7", // Soft lavender for secondary elements
            tertiary: "#3E3A5D", // Deep navy for highlights and emphasis
            background: "#FAF8FC", // Very light lavender for background
            surface: "#F0EBF4", // Light lavender for surfaces
            error: "#D64550", // Softer red for errors
            success: "#4CAF50", // Medium green for success
            info: "#2196F3", // Medium blue for info
            warning: "#FFC107", // Bright amber for warnings
            ...customColors,
          },
        },
        pink: {
          dark: false,
          colors: {
            primary: "#D81B60", // Vibrant pink for primary elements
            secondary: "#F8BBD0", // Soft pink for secondary elements
            tertiary: "#880E4F", // Deep magenta for highlights and emphasis
            background: "#FFF0F3", // Very light pink for background
            surface: "#FFE6EB", // Light pink for surfaces
            error: "#E57373", // Softer red for errors
            success: "#81C784", // Soft green for success
            info: "#64B5F6", // Soft blue for info
            warning: "#FFB74D", // Soft amber for warnings
            ...customColors,
          },
        },
        // kanters: {
        //   dark: false,
        //   colors: {
        //     primary: "#e20036",
        //     secondary: "#BAD8E6",
        //     tertiary: "#3C3F45",
        //     background: "#FFFFFF",
        //     surface: "#F8F8F8",
        //     error: "#C10015",
        //     success: "#21BA45",
        //     info: "#1BA6C8",
        //     warning: "#F0AD4E",
        //   },
        // },
        orange: {
          dark: false,
          colors: {
            primary: "#E65100", // Deeper orange for primary elements
            secondary: "#FFCC80", // Soft orange for secondary elements
            tertiary: "#BF360C", // Very deep orange for highlights and emphasis
            background: "#FFF3E0", // Very light orange for background
            surface: "#FFE0B2", // Light orange for surfaces
            error: "#D32F2F", // Noticeable red for errors
            success: "#388E3C", // Medium green for success
            info: "#1976D2", // Medium blue for info
            warning: "#F57C00", // Bright amber for warnings
            ...customColors,
          },
        },
        green: {
          dark: false,
          colors: {
            primary: "#388E3C", // Vibrant green for primary elements
            secondary: "#A5D6A7", // Soft green for secondary elements
            tertiary: "#2E7D32", // Deep green for highlights and emphasis
            background: "#F1F8F4", // Very light green for background
            surface: "#E8F5E9", // Light green for surfaces
            error: "#E57373", // Softer red for errors
            success: "#66BB6A", // Bright green for success
            info: "#4FC3F7", // Soft blue for info
            warning: "#FFB74D", // Soft amber for warnings
            ...customColors,
          },
        },
      },
    },
  })

  vueApp.use(vuetify)
})
