export interface SnackbarAction {
  label: string
  handler: () => void
}

export interface Snackbar {
  title: string
  text?: string
  type: "success" | "error" | "warning" | "info"
  timeout?: number
  actions?: Array<SnackbarAction>
  key?: string
  closable?: boolean
  pending?: boolean
}

const snackbars = ref<Array<Snackbar>>([])

export const useMainStore = defineStore("main", () => {
  const notify = (snackbar: Snackbar): Snackbar => {
    snackbars.value.push({ ...snackbar, key: Math.random().toString() })
    return snackbars.value[snackbars.value.length - 1]
  }

  return {
    snackbars,
    notify,
  }
})
