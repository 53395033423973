import dayjs from "dayjs"
import { jwtDecode } from "jwt-decode"
import type { CookieRef } from "nuxt/app"

export const logout = async (redirect?: string) => {
  const params = new URLSearchParams()
  params.set("logout", "true")
  if (redirect && redirect !== "/") {
    params.set("redirect", redirect)
  }

  return await navigateTo(`/?${params.toString()}`)
}

const fetchRefreshedToken = async (token: CookieRef<string | undefined | null>) => {
  const firebaseAuth = useFirebaseAuth()

  if (!firebaseAuth) {
    throw new Error("No firebase auth")
  }

  console.time("refreshToken")

  // Wait for firebase to initialize before continuing
  return new Promise<string>((resolve, reject) => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
      try {
        if (!user) {
          throw new Error("No authenticated user")
        }

        const refreshedFirebaseToken = await user.getIdToken(true)

        const runtimeConfig = useRuntimeConfig()

        const response = await fetch(runtimeConfig.public.BACKEND_URL + "/auth/refreshToken", {
          method: "POST",
          body: JSON.stringify({ firebaseToken: refreshedFirebaseToken }),
          headers: {
            Authorization: `Bearer ${token.value}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })

        if (!response) {
          throw new Error("Could not refresh token")
        }

        const data = (await response.json()) as TokenResponse

        if (!data) {
          throw new Error("Could not refresh token")
        }

        resolve(data.token)
      } catch (e: unknown) {
        reject(e)
      } finally {
        unsubscribe()
      }
    })
  }).finally(() => {
    console.timeEnd("refreshToken")
  })
}

const datePlus30Days = dayjs().add(30, "day").toDate()

export default defineNuxtRouteMiddleware(async (to) => {
  const token = useCookie<string | undefined>("sam_token", { expires: datePlus30Days })

  if (!token.value) {
    // if already on login page, do nothing
    if (to.path === "/") {
      return
    }

    console.warn("No authentication token found, redirecting to login...")
    return logout(to.path)
  }

  const decodedToken = jwtDecode<TokenBody>(token.value)
  const decodedFirebaseToken = jwtDecode<FirebaseAuthToken>(decodedToken.firebaseToken)
  const nowEpochInSeconds = new Date().getTime() / 1000
  const tokenExpired = decodedFirebaseToken.exp < nowEpochInSeconds

  if (tokenExpired) {
    try {
      console.warn("Token expired, refreshing...")
      const newToken = await fetchRefreshedToken(token)

      token.value = newToken
      console.warn("Token refreshed!")
      return
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      token.value = ""
      console.error("Could not refresh token", errorMessage)
      console.warn(`redirecting to login...`)
      return logout(to.path)
    }
  }

  if (to.fullPath === "/") {
    if (decodedToken.organizations.length > 0) {
      console.warn("Already logged in, redirecting to dashboard...")
      return navigateTo("/dashboard")
    } else {
      console.warn("No organizations found on token, redirecting to login organization select page...")
      return navigateTo("/?switch_organization=true")
    }
  }
})
