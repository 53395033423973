import type { PaginateResult } from "mongoose"
import type { Budget } from "./budget.store"
import type { PopulatedComponentProperty } from "./components.store"
import type { FileData } from "./file.store"
import type { Measurement } from "./measurement.store"
import type { StatusDescription } from "./status.store"
import type { GeoPoint, RiskAnalysis, MovementType } from "./types"
import type { Ticket, TicketType } from "./ticket.store"
import type { AssetTag } from "./assetTag.store"
import type { RiskProfile } from "./riskSettings.store"

export enum GeneralCondition {
  UNKNOWN = "UNKNOWN",
  GOOD = "GOOD",
  DECENT = "DECENT",
  INSUFFICIENT = "INSUFFICIENT",
  BAD = "BAD",
}

export enum MarkerSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface AssetComponentData {
  data: Array<Partial<Asset> & { _id: string } & Record<string, unknown>>
  fields: Record<string, Record<string, unknown>>
}

export interface AssetCategoryMarker {
  description: string
  color: string
  size: MarkerSize
}

export interface Deprecation {
  component: string
  interval: number
}

export interface AssetCategory {
  _id: string
  organization: string
  description: string
  marker: AssetCategoryMarker
  deprecation: Array<Deprecation>
}

export interface AssetRelationType {
  _id: string
  organization?: string
  description: string
  description1: string
  description2: string
}

export interface PopulatedAssetRelation {
  _id: string
  asset1: LookupAsset
  asset2: LookupAsset
  relationType: AssetRelationType
  organization: string
  description?: string
}

export interface AssetRelation {
  _id: string
  asset1: string
  asset2: string
  relationType: string
  organization: string
  description?: string
}

export interface Location {
  city?: string
  street?: string
  houseNumber?: string
  zipCode?: string
  district?: string
  area?: string
  municipality?: string
  gps?: GeoPoint
  rd?: GeoPoint
  distance?: number
}

export interface ExternalKey {
  _id: string
  application: string
  value: string
  isDefault: boolean
  lastUsedAt?: string
  lastUsedBy?: string
}

export interface PopulatedExternalKey extends Omit<ExternalKey, "application"> {
  application: ExternalKeyApplication
}

export interface LookupAsset {
  _id: string
  organization: string
  description: string
  template: string
  key: string
  location?: Location & { distance?: number }
  category?: string
  images?: Array<string>
  distance?: number
  tags?: Array<string>
  ticketCount?: {
    inspection: number
    malfunction: number
    action: number
  }
}

export interface SearchAsset {
  _id: Asset["_id"]
  description: Asset["description"]
  key: Asset["key"]
  location: Asset["location"]
  organization: Pick<Organization, "_id" | "description">
  category: Pick<AssetCategory, "_id" | "marker" | "description">
  tags: Array<Pick<AssetTag, "_id" | "color">>
  template: Asset["template"]
}

export interface AssetComponentCondition {
  rating: GeneralCondition
  date: string
  remark?: string
  inspection?: string
}

export interface ConditionsResponse {
  group: string
  component: string
  groupDescription?: string
  componentDescription?: string
  condition: AssetComponentCondition
}

export interface AssetValue {
  _id: string
  componentProperty: string
  value: string
  lookupValue?: string
}

export type AssetValueForRequest = Omit<AssetValue, "_id"> & { _id?: string }

export interface PopulatedAssetValue extends Omit<AssetValue, "lookupValue"> {
  lookupValue?: {
    _id: string
    files: Array<FileData>
    price?: number
    priceYear?: number
  }
}

export interface AssetComponentBudget {
  interval?: number
  price?: number
  priceYear?: number
}

export interface AssetComponent {
  _id: string
  key?: string
  component: string
  properties?: Array<PopulatedComponentProperty>
  optionalProperties?: Array<string>
  description?: string
  values?: Array<PopulatedAssetValue>
  condition?: AssetComponentCondition
  componentType?: string
  files: Array<FileData>
  budget?: AssetComponentBudget
  archived?: boolean
}

export interface AssetComponentGroup {
  _id: string
  key?: string
  componentGroup: string
  description?: string
  components: Array<AssetComponent>
  archived?: boolean
}

export interface Asset {
  _id: string
  organization: string
  category: string
  template: string
  componentGroups?: Array<AssetComponentGroup>
  description: string
  key: string
  year?: number
  remark?: string
  location: Location
  riskProfile?: RiskProfile
  externalKeys: Array<ExternalKey>
  images: Array<string>
  documents: Array<string>
  tags?: Array<string>
  riskScore?: number
  missingRiskValues?: number
}

export interface TicketCount {
  inspections: number
  malfunctions: number
  actions: number
}

export interface TimelineTicket {
  _id: string
  type: TicketType | "MUTATION"
  description: string
  date: string
  status?: StatusDescription
  property?: string
  users?: Array<{ _id?: string; description: string }>
  supplier: string
  remark?: string
  hasAccess: boolean
  transactionId?: string
}

export interface AssetBatchPayload {
  riskProfile?: RiskProfile
  category?: string
  tags?: Array<string>
}

export type YearlyCostsTicket = Pick<
  Ticket,
  | "_id"
  | "description"
  | "type"
  | "costsTotal"
  | "costsMaterial"
  | "costsLabor"
  | "laborHours"
  | "date"
  | "articles"
  | "contract"
  | "invoicePeriod"
  | "supplier"
  | "users"
>

export interface AssetBasicYearlyCosts {
  year: number
  costsTotal: number
  laborHours: number
  costsMaterial: number
  costsLabor: number
  /** The trend of the costs compared to the previous year (percentage) */
  trendTotal: number
}

export interface TicketCost {
  type: TicketType
  costsTotal: number
  laborHours: number
  costsMaterial: number
  costsLabor: number
}

export interface AssetYearlyCosts extends AssetBasicYearlyCosts {
  tickets: Array<YearlyCostsTicket>
  ticketCosts: Array<TicketCost>
}

export interface AssetBasicCostsResponse {
  yearlyCosts: Array<AssetBasicYearlyCosts>
}

export interface AssetCostsResponse {
  yearlyCosts: Array<AssetYearlyCosts>
}

export interface GenerateAssetPdfOptions {
  imageVisibility?: "HIDE_ALL" | "SHOW_MAIN" | "SHOW_ALL"
}

export const useAssetStore = defineStore("asset", () => {
  /** @deprecated */
  const assets = ref<Array<Asset>>([])
  /** @deprecated */
  const assetsLookup = ref<Array<LookupAsset>>([])
  /** @deprecated */
  const relations = ref<Array<AssetRelation>>([])
  /** @deprecated */
  const relationTypes = ref<Array<AssetRelationType>>([])
  /** @deprecated */
  const categories = ref<Array<AssetCategory>>([])
  /** @deprecated */
  const settingCategories = ref<Array<AssetCategory>>([])
  /** @deprecated */
  const categoryAssetCount = ref<Array<CountAggregate>>([])
  /** @deprecated */
  const componentGroups = ref<Record<string, Array<AssetComponentGroup>>>({})

  const assetsMapper = computed<Record<string, number>>(() => {
    const mapper: Record<string, number> = {}
    for (let i = 0; i < assets.value.length; i++) mapper[assets.value[i]._id] = i
    return mapper
  })

  const findAsset = (id: string): Asset | undefined => {
    const idx = assetsMapper.value[id]
    if (idx) return assets.value[idx]
  }

  const getAssetCount = async (queryParameters?: Record<string, unknown>): Promise<number> => {
    const searchParams = new URLSearchParams()
    if (queryParameters) {
      searchParams.append("query", JSON.stringify(queryParameters))
    }

    const { data } = await useSamApi<{ count: number }>(`/assets/count?${searchParams}`)
    return data.count
  }

  /** Return ALL the categories that the user has permission to see, also the ones not used by any asset */
  const getCategories = async (organization?: string): Promise<Array<AssetCategory>> => {
    return useCrudMethods("/asset-categories", categories).readItems({ pagination: false, query: JSON.stringify({ organization }) })
  }

  /** Return ALL the categories that exist for an organization, only for use by admins in the settings pages  */
  const getCategoriesForSettings = async (organization: string): Promise<Array<AssetCategory>> => {
    const { data } = await useSamApi<Array<AssetCategory>>(`/asset-categories/all-categories/${organization}`)
    settingCategories.value = data
    return data
  }

  /** Returns the categories in use by the assets, which the user has permission to see */
  const getUsedCategories = async (queryParameters?: Record<string, unknown>): Promise<Array<AssetCategory>> => {
    const query = getUrlSearchParams(queryParameters)
    const result = await useSamApi<Array<AssetCategory>>(`/asset-categories/used?${query}`)
    return result.data
  }

  const getCategoryById = async (id: string): Promise<AssetCategory> => {
    return useCrudMethods("/asset-categories", categories).readItemById(id)
  }

  const updateCategory = async (id: string, category: Partial<AssetCategory>): Promise<AssetCategory> => {
    return useCrudMethods("/asset-categories", categories).updateItem(id, category)
  }

  const createCategory = async (category: Partial<AssetCategory>): Promise<AssetCategory> => {
    return useCrudMethods("/asset-categories", categories).createItem(category)
  }

  const getCategoryAssetCount = async (organization?: string): Promise<Array<CountAggregate>> => {
    const { data } = await useSamApi<Array<CountAggregate>>(
      `/asset-categories/asset-count${organization ? `?organization=${organization}` : ""}`,
    )
    categoryAssetCount.value = data
    return data
  }

  const deleteCategory = async (id: string): Promise<AssetCategory> => {
    return useCrudMethods("/asset-categories", categories).deleteItem(id)
  }

  const createAsset = async (asset: Partial<Asset>): Promise<Asset> => {
    return useCrudMethods("/assets", assets).createItem(asset)
  }

  const getAssets = async (queryParameters: Record<string, unknown> = {}): Promise<Array<Asset>> => {
    return useCrudMethods("/assets", assets).readItems({ pagination: false, ...queryParameters })
  }

  const getAssetsByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Asset>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<Asset>>(`/assets?${query}`)
    return data
  }

  const getAssetsComponents = async (componentTypes?: string | Array<string>): Promise<AssetComponentData> => {
    const queryParameters = new URLSearchParams()
    if (componentTypes) {
      if (Array.isArray(componentTypes)) {
        componentTypes.forEach((type) => queryParameters.append("type", type))
      } else {
        queryParameters.append("type", componentTypes)
      }
    }

    const { data } = await useSamApi<AssetComponentData>(`/assets/components?${queryParameters}`)
    return data
  }

  const getAssetsNearby = async (
    id: string,
    distance = 1000,
    limit?: number,
    query?: Record<string, unknown>,
  ): Promise<Array<LookupAsset>> => {
    const queryParameters = new URLSearchParams()
    if (query) {
      queryParameters.append("query", JSON.stringify(query))
    }
    if (limit) {
      queryParameters.append("limit", limit.toString())
    }

    const { data } = await useSamApi<Array<LookupAsset>>(`/assets/${id}/near/${distance}?${queryParameters}`)
    return data
  }

  const getAssetsNearbyCoords = async (
    x: number,
    y: number,
    distance = 1000,
    limit?: number,
    query?: Record<string, unknown>,
  ): Promise<Array<LookupAsset>> => {
    const queryParameters = new URLSearchParams()
    if (query) {
      queryParameters.append("query", JSON.stringify(query))
    }
    if (limit) {
      queryParameters.append("limit", limit.toString())
    }

    const { data } = await useSamApi<Array<LookupAsset>>(`/assets/near/${x}/${y}/${distance}?${queryParameters}`)
    return data
  }

  const getAssetsLookup = async (queryParameters: Record<string, unknown> = {}): Promise<Array<LookupAsset>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<Array<LookupAsset>>(`/assets/lookup?${query}`)

    assetsLookup.value = data
    return data
  }

  const getAssetById = async (id: string): Promise<Asset> => {
    return useCrudMethods("/assets", assets).readItemById(id)
  }

  const updateAsset = async (id: string, asset: Partial<Asset>): Promise<Asset> => {
    return useCrudMethods("/assets", assets).updateItem(id, asset)
  }

  const deleteAsset = async (id: string): Promise<Asset> => {
    return useCrudMethods("/assets", assets).deleteItem(id)
  }

  const getComponentGroups = async (id: string): Promise<Array<AssetComponentGroup>> => {
    const { data } = await useSamApi<Array<AssetComponentGroup>>(`/assets/${id}/groups`)

    const asset = assets.value.find((asset) => asset._id === id)
    if (asset) {
      asset.componentGroups = data
    }

    componentGroups.value = { ...componentGroups.value, [id]: data }

    return data
  }

  const getExternalKeys = async (id: string): Promise<Array<PopulatedExternalKey>> => {
    const { data } = await useSamApi<Array<PopulatedExternalKey>>(`/assets/${id}/external-keys`)
    return data
  }

  const getAssetByExternalKey = async (externalKey: string): Promise<Asset> => {
    const { data } = await useSamApi<Asset>(`/assets/external-key/${externalKey}`)
    return data
  }

  const getAllAssetsWithExternalKeys = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Asset>> => {
    const query = getUrlSearchParams(queryParameters)
    const { data } = await useSamApi<PaginateResult<Asset>>(`/assets/all-external-keys-assets?${query}`)
    return data
  }

  const addExternalKey = async (id: string, externalKey: Partial<ExternalKey>): Promise<Array<ExternalKey>> => {
    const { data } = await useSamApi<Array<ExternalKey>>(`/assets/${id}/external-keys`, {
      method: "POST",
      body: JSON.stringify(externalKey),
    })
    return data
  }

  const updateExternalKey = async (id: string, externalKeyId: string, externalKey: Partial<ExternalKey>): Promise<Array<ExternalKey>> => {
    const { data } = await useSamApi<Array<ExternalKey>>(`/assets/${id}/external-keys/${externalKeyId}`, {
      method: "PUT",
      body: JSON.stringify(externalKey),
    })
    return data
  }

  const deleteExternalKey = async (id: string, externalKeyId: string): Promise<Array<ExternalKey>> => {
    const { data } = await useSamApi<Array<ExternalKey>>(`/assets/${id}/external-keys/${externalKeyId}`, { method: "DELETE" })
    return data
  }

  const createRelation = async (relation: Partial<AssetRelation>): Promise<AssetRelation> => {
    return useCrudMethods("/asset-relations", relations).createItem(relation)
  }

  const getRelations = async (): Promise<Array<AssetRelation>> => {
    return useCrudMethods("/asset-relations", relations).readItems({ pagination: false })
  }

  const getRelationsByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<AssetRelation>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<AssetRelation>>(`/asset-relations?${query}`)
    return data
  }

  const getRelationsByAssetId = async (assetId: string): Promise<Array<PopulatedAssetRelation>> => {
    try {
      const { data } = await useSamApi<Array<PopulatedAssetRelation>>(`/assets/${assetId}/relations`)
      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  const getRelationById = async (id: string): Promise<AssetRelation> => {
    return useCrudMethods("/asset-relations", relations).readItemById(id)
  }

  const updateRelation = async (id: string, relation: Partial<AssetRelation>): Promise<AssetRelation> => {
    return useCrudMethods("/asset-relations", relations).updateItem(id, relation)
  }

  const deleteRelation = async (id: string): Promise<AssetRelation> => {
    return useCrudMethods("/asset-relations", relations).deleteItem(id)
  }

  const getRelationTypes = async (organization?: string): Promise<Array<AssetRelationType>> => {
    return useCrudMethods("/asset-relation-types", relationTypes).readItems({ pagination: false, query: JSON.stringify({ organization }) })
  }

  const getRelationTypeById = async (id: string): Promise<AssetRelationType> => {
    return useCrudMethods("/asset-relation-types", relationTypes).readItemById(id)
  }

  const updateRelationType = async (id: string, relationType: Partial<AssetRelationType>): Promise<AssetRelationType> => {
    return useCrudMethods("/asset-relation-types", relationTypes).updateItem(id, relationType)
  }

  const createRelationType = async (relationType: Partial<AssetRelationType>): Promise<AssetRelationType> => {
    return useCrudMethods("/asset-relation-types", relationTypes).createItem(relationType)
  }

  const deleteRelationType = async (id: string): Promise<AssetRelationType> => {
    return useCrudMethods("/asset-relation-types", relationTypes).deleteItem(id)
  }

  const getImages = async (assetId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/assets/${assetId}/images`)
    return data
  }

  const getFiles = async (assetId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/assets/${assetId}/documents`)
    return data
  }

  const addImagesToAsset = async (assetId: string, files: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    files.forEach((file) => formData.append("files", file))

    const { data: newFiles } = await useSamApi<Array<FileData>>(
      `/assets/${assetId}/images`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    await getAssetById(assetId)
    return newFiles
  }

  const addFilesToAsset = async (assetId: string, files: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    files.forEach((file) => formData.append("files", file))

    const { data: newFiles } = await useSamApi<Array<FileData>>(
      `/assets/${assetId}/documents`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    await getAssetById(assetId)
    return newFiles
  }

  const removeImageFromAsset = async (id: string, fileId: string) => {
    await useSamApi<Asset>(`/assets/${id}/images/${fileId}`, { method: "DELETE" })
    return getAssetById(id)
  }

  const removeFileFromAsset = async (id: string, fileId: string) => {
    await useSamApi<Asset>(`/assets/${id}/documents/${fileId}`, { method: "DELETE" })
    return getAssetById(id)
  }

  const updateImagesOrder = async (id: string, images: Array<string>) => {
    const { data } = await useSamApi<Array<string>>(`/assets/${id}/images`, { method: "PUT", body: JSON.stringify(images) })
    return data
  }

  const updateFilesOrder = async (id: string, files: Array<string>) => {
    const { data } = await useSamApi<Array<string>>(`/assets/${id}/documents`, { method: "PUT", body: JSON.stringify(files) })
    return data
  }

  const updateComponentGroupsOrder = async (assetId: string, movement: MovementType, componentGroupId: string) => {
    const { data } = await useSamApi<Array<string>>(`/assets/${assetId}/groups`, {
      method: "PUT",
      body: JSON.stringify({
        movement,
        componentGroupId,
      }),
    })

    return data
  }

  const updateComponentsOrder = async (assetId: string, movement: MovementType, componentGroupId: string, componentId: string) => {
    const { data } = await useSamApi<Array<string>>(`/assets/${assetId}/groups/${componentGroupId}/components`, {
      method: "PUT",
      body: JSON.stringify({
        movement,
        componentId,
      }),
    })

    return data
  }

  const updateComponentValues = async (
    id: string,
    groupId: string,
    componentId: string,
    componentValues: Array<AssetValueForRequest>,
  ): Promise<Array<PopulatedAssetValue>> => {
    const { data } = await useSamApi<Array<PopulatedAssetValue>>(`/assets/${id}/groups/${groupId}/components/${componentId}/values`, {
      method: "PUT",
      body: JSON.stringify(componentValues),
    })

    return data
  }

  const addComponentFiles = async (assetId: string, groupId: string, componentId: string, files: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    files.forEach((file) => formData.append("files", file))

    const { data: newFiles } = await useSamApi<Array<FileData>>(
      `/assets/${assetId}/groups/${groupId}/components/${componentId}/files`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    await getComponentGroups(assetId)
    return newFiles
  }

  const removeComponentFile = async (id: string, groupId: string, componentId: string, fileId: string): Promise<void> => {
    await useSamApi(`/assets/${id}/groups/${groupId}/components/${componentId}/files/${fileId}`, { method: "DELETE" })
  }

  const getConditionsByAssetId = async (assetId: string): Promise<Array<ConditionsResponse>> => {
    const { data } = await useSamApi<Array<ConditionsResponse>>(`/assets/${assetId}/conditions`)
    return data
  }

  const updateCondition = async (
    assetId: string,
    groupId: string,
    componentId: string,
    condition: AssetComponentCondition,
  ): Promise<Asset> => {
    try {
      const { data } = await useSamApi<Asset>(`/assets/${assetId}/groups/${groupId}/components/${componentId}/condition`, {
        method: "PUT",
        body: JSON.stringify(condition),
      })
      return data
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const addGroup = async (id: string, group: Partial<AssetComponentGroup>): Promise<Array<AssetComponentGroup>> => {
    const { data } = await useSamApi<Array<AssetComponentGroup>>(`/assets/${id}/groups`, { method: "POST", body: JSON.stringify(group) })

    await getComponentGroups(id)

    return data
  }

  const removeGroup = async (id: string, groupId: string): Promise<Array<AssetComponentGroup>> => {
    const { data } = await useSamApi<Array<AssetComponentGroup>>(`/assets/${id}/groups/${groupId}`, { method: "DELETE" })

    await getComponentGroups(id)

    return data
  }

  const updateGroupDescription = async (id: string, groupId: string, description?: string): Promise<void> => {
    await useSamApi(`/assets/${id}/groups/${groupId}/description`, {
      method: "PUT",
      body: JSON.stringify({ description }),
    })
  }

  const addComponent = async (id: string, groupId: string, component: Partial<AssetComponent>): Promise<Array<AssetComponent>> => {
    const { data } = await useSamApi<Array<AssetComponent>>(`/assets/${id}/groups/${groupId}/components`, {
      method: "POST",
      body: JSON.stringify(component),
    })

    await getComponentGroups(id)

    return data
  }

  const updateComponent = async (
    id: string,
    groupId: string,
    componentId: string,
    component: Partial<AssetComponent>,
  ): Promise<Array<AssetComponent>> => {
    const { data } = await useSamApi<Array<AssetComponent>>(`/assets/${id}/groups/${groupId}/components/${componentId}`, {
      method: "PUT",
      body: JSON.stringify(component),
    })

    await getComponentGroups(id)

    return data
  }

  const removeComponent = async (id: string, groupId: string, componentId: string): Promise<Array<AssetComponent>> => {
    const { data } = await useSamApi<Array<AssetComponent>>(`/assets/${id}/groups/${groupId}/components/${componentId}`, {
      method: "DELETE",
    })

    await getComponentGroups(id)

    return data
  }

  const getMeasurements = async (id: string, limit?: number): Promise<Array<Measurement>> => {
    const { data } = await useSamApi<Array<Measurement>>(`/assets/${id}/measurements${limit ? `?limit=${limit}` : ""}`)
    return data
  }

  const getTicketCount = async (id: string, ...statuses: Array<string>): Promise<TicketCount> => {
    const params = new URLSearchParams()
    statuses.forEach((status) => params.append("status", status))

    const { data } = await useSamApi<TicketCount>(`/assets/${id}/ticket-count?${params}`)
    return data
  }

  const getTimelineTickets = async (id: string): Promise<Array<TimelineTicket>> => {
    const { data } = await useSamApi<Array<TimelineTicket>>(`/assets/${id}/timeline`)
    return data
  }

  const getBudgets = async (id: string): Promise<Array<Budget>> => {
    const { data } = await useSamApi<Array<Budget>>(`/assets/${id}/budgets`)
    return data
  }

  const getRiskAnalysis = async (id: string): Promise<RiskAnalysis> => {
    const { data } = await useSamApi<RiskAnalysis>(`/assets/${id}/risk-analysis`)
    return data
  }

  const generateRiskAnalysis = async (): Promise<void> => {
    await useSamApi(`/assets/generate-risk-analysis`, { method: "POST" })
  }

  const searchAssets = async (query: string, limit?: number): Promise<Array<SearchAsset>> => {
    const { data } = await useSamApi<Array<SearchAsset>>(`/assets/search?q=${query}${limit ? `&limit=${limit}` : ""}`)
    return data
  }

  const getAssetTags = async (id: string): Promise<Array<AssetTag>> => {
    const { data } = await useSamApi<Array<AssetTag>>(`/assets/${id}/tags`)
    return data
  }

  const batchEditAssets = async (assetIds: Array<string>, assetData: AssetBatchPayload): Promise<Array<Asset>> => {
    const { data } = await useSamApi<Promise<Array<Asset>>>(`/assets/batch-edit`, {
      method: "POST",
      body: JSON.stringify({ assetIds, assetData }),
    })

    return data
  }

  const moveAssets = async (assetIdsToMove: Array<string>, targetOrganization: string): Promise<void> => {
    await useSamApi(`/assets/move-assets`, {
      method: "POST",
      body: JSON.stringify({
        assets: assetIdsToMove,
        organization: targetOrganization,
      }),
    })
  }

  const replaceTemplate = async (assetIds: Array<string>, templateId: string): Promise<undefined> => {
    await useSamApi(`/assets/replace-component-groups`, {
      method: "POST",
      body: JSON.stringify({
        assetIds,
        templateId,
      }),
    })
  }

  const getAssetHistoryCount = async (organization?: string) => {
    const searchParams = getUrlSearchParams({ organization })

    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/assets/history-count?${searchParams}`)
    return data
  }

  const getBasicAssetCosts = async (id: string, options?: { fromYear?: number; toYear?: number }): Promise<AssetBasicCostsResponse> => {
    const searchParams = getUrlSearchParams(options)

    const { data } = await useSamApi<AssetBasicCostsResponse>(`/assets/${id}/costs/basic?${searchParams}`)
    return data
  }

  const getDetailedAssetCosts = async (id: string, options?: { fromYear?: number; toYear?: number }): Promise<AssetCostsResponse> => {
    const searchParams = getUrlSearchParams(options)

    const { data } = await useSamApi<AssetCostsResponse>(`/assets/${id}/costs/detailed?${searchParams}`)
    return data
  }

  const generateAssetPdf = async (assetId: string, options?: GenerateAssetPdfOptions) => {
    const searchParams = getUrlSearchParams(options)

    const { data: pdf } = await useSamApi<Blob>(`/assets/${assetId}/pdf?${searchParams}`, {
      headers: { Accept: "*/*" },
      method: "POST",
    })

    return URL.createObjectURL(pdf)
  }

  const getAssetDataQuality = async (id: string): Promise<AssetDataQuality | null> => {
    const { data } = await useSamApi<AssetDataQuality | null>(`/assets/${id}/data-quality`)
    return data
  }

  return {
    assets,
    assetsLookup,
    categories,
    settingCategories,
    relations,
    relationTypes,
    categoryAssetCount,
    componentGroups,

    getCategories,
    getUsedCategories,
    getCategoriesForSettings,
    getCategoryById,
    updateCategory,
    createCategory,
    getCategoryAssetCount,
    deleteCategory,

    createAsset,
    getAssets,
    getAssetsByPage,
    getAssetById,
    getAssetsNearby,
    getAssetsNearbyCoords,
    getAssetsLookup,
    getAssetsComponents,
    updateAsset,
    deleteAsset,
    getImages,
    getFiles,
    addImagesToAsset,
    addFilesToAsset,
    removeImageFromAsset,
    removeFileFromAsset,
    updateImagesOrder,
    updateFilesOrder,
    updateComponentGroupsOrder,
    updateComponentsOrder,

    getAllAssetsWithExternalKeys,
    getExternalKeys,
    addExternalKey,
    updateExternalKey,
    deleteExternalKey,
    getAssetByExternalKey,

    getComponentGroups,
    getConditionsByAssetId,
    addGroup,
    addComponent,
    addComponentFiles,
    updateGroupDescription,
    updateComponent,
    updateComponentValues,
    updateCondition,
    removeComponentFile,
    removeGroup,
    removeComponent,

    createRelation,
    getRelations,
    getRelationsByPage,
    getRelationById,
    getRelationsByAssetId,
    updateRelation,
    deleteRelation,

    getRelationTypes,
    getRelationTypeById,
    updateRelationType,
    createRelationType,
    deleteRelationType,

    getMeasurements,
    getTicketCount,
    getTimelineTickets,

    getBudgets,
    getRiskAnalysis,
    generateRiskAnalysis,
    findAsset,

    searchAssets,
    getAssetCount,
    getAssetHistoryCount,

    getAssetTags,

    batchEditAssets,
    moveAssets,
    replaceTemplate,

    getBasicAssetCosts,
    getDetailedAssetCosts,

    generateAssetPdf,

    getAssetDataQuality,
  }
})
