import isEmpty from "lodash-es/isEmpty"
import type { PaginateResult, QueryOptions } from "mongoose"
import type { Action } from "./action.store"
import type { FileData } from "./file.store"
import type { Measurement } from "./measurement.store"
import type { Ticket, TicketType } from "./ticket.store"
import type { DocumentDescription } from "./types"
import type { StatusDescription } from "./status.store"
import merge from "lodash-es/merge"
import type { GeneralCondition } from "./asset.store"

export const enum BrlValue {
  GOOD = "GOOD",
  DECENT = "DECENT",
  BAD = "BAD",
  REPLACED = "REPLACED",
  NA = "NA",
}

export enum InspectionPropertyValueType {
  BRL = "BRL",
  BRL_SIMPLE = "BRL_SIMPLE",
  RADIO = "RADIO",
  NUMBER = "NUMBER",
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX",
}

export interface InspectionType {
  _id: string
  organization: string
  description: string
  interval?: number
}

export interface InspectionPropertyMeterReadingSettings {
  suffix?: string
  prefix?: string
}

export interface InspectionProperty {
  _id: string
  inspectionType: string
  sortOrder: number
  description: string
  valueType: InspectionPropertyValueType
  hasMeterReading: boolean
  meterReadingSettings?: InspectionPropertyMeterReadingSettings
  brlInfo?: string
  organization?: string
  allowedValues: Array<string>
}

export type CreateInspectionPayload = Omit<Extract<CreateTicketPayload, { type: TicketType.INSPECTION }>, "type">

export type DocumentDescriptionWithCaption = DocumentDescription & { captionValues: Array<string> }
export type InspectionDocumentDescription = DocumentDescription & { date?: string; status: StatusDescription }

export type InspectionPropertySubset = Pick<
  InspectionProperty,
  "_id" | "description" | "valueType" | "allowedValues" | "hasMeterReading" | "meterReadingSettings" | "brlInfo"
>

export type PreviousInspectionValue = Pick<InspectionValue, "_id" | "value" | "meterReadingValue" | "inspection">

export enum InspectionValueDifference {
  IMPROVED = "improved",
  DETERIORATED = "deteriorated",
  UNCHANGED = "unchanged",
}

export type InspectionValue = {
  _id: string
  asset: TicketAsset
  inspection: InspectionDocumentDescription
  assetComponentGroup: DocumentDescriptionWithCaption
  componentGroup: DocumentDescription
  assetComponent?: DocumentDescriptionWithCaption
  component?: DocumentDescription
  remark?: string
  files: Array<string>
  meterReadingValue?: string
  /** Could be undefined in migrated inspections from SAM 3 or when it is a general condition */
  inspectionProperty?: InspectionPropertySubset
  previousValue?: PreviousInspectionValue
  difference?: InspectionValueDifference
} & (
  | {
      isGeneralCondition: true
      value?: GeneralCondition
    }
  | {
      isGeneralCondition: false
      /** Type depends on the valueType of the inspectionProperty and if it is a general condition */
      value?: string
    }
)

export type UpdateInspectionValuePayload = Pick<InspectionValue, "files" | "meterReadingValue" | "value" | "remark">

export interface InspectionData {
  inspectionType: string
}

export interface InspectionPropertyFilesResponse {
  inspectionPropertyFiles: Array<InspectionPropertyFiles>
}

interface InspectionPropertyFiles {
  groupDescription: string
  componentDescription?: string
  propertyDescription?: string
  files: Array<FileData>
}

export type Inspection = Extract<Ticket, { type: TicketType.INSPECTION }>
export type ListInspection = Extract<ListTicket, { type: TicketType.INSPECTION }>
export type OverviewInspection = Extract<OverviewTicket, { type: TicketType.INSPECTION }>

export const useInspectionStore = defineStore("inspection", () => {
  /** @deprecated */
  const inspections = ref<Array<Inspection>>([])
  /** @deprecated */
  const listInspections = ref<Array<ListInspection>>([])
  /** @deprecated */
  const types = ref<Array<InspectionType>>([])

  const createInspection = async (ticketData: CreateInspectionPayload): Promise<Inspection> => {
    const { data: inspection } = await useSamApi<Inspection>(`/tickets/inspections`, {
      method: "POST",
      body: JSON.stringify({ ticketData }),
    })
    return inspection
  }

  const getInspections = async (queryParameters?: QueryOptions) => {
    return await useCrudMethods<Inspection>("/tickets/inspections", inspections).readItems(queryParameters)
  }

  const getInspectionById = async (id: string) => {
    return await useCrudMethods<Inspection>("/tickets/inspections", inspections).readItemById(id)
  }

  const getInspectionList = async (queryParameters?: QueryOptions) => {
    return await useCrudMethods<ListInspection>("/tickets/inspections/list", listInspections).readItems(queryParameters)
  }

  const getInspectionListByAssetId = async (assetId: string, queryParameters?: QueryOptions) => {
    return await useCrudMethods<ListInspection>(`/assets/${assetId}/inspections`, listInspections).readItems(queryParameters)
  }

  const updateInspection = async (id: string, inspection: Partial<Inspection>) => {
    // Remove asset from inspection to prevent error
    const { asset, ...copy } = inspection
    return await useCrudMethods<Inspection>("/tickets/inspections", inspections).updateItem(id, copy)
  }

  const updateStatus = async (id: string, status: string) => {
    return await useCrudMethods<Inspection>("/tickets/inspections", inspections).updateItemCustom(id, `${id}/status`, { status })
  }

  const deleteInspection = async (id: string) => {
    return await useCrudMethods<Inspection>("/tickets/inspections", inspections).deleteItem(id)
  }

  const getTypes = async (queryParameters?: QueryOptions) => {
    return await useCrudMethods<InspectionType>("/inspection-types", types).readItems(queryParameters)
  }

  const getMeasurement = async (id: string) => {
    const { data } = await useSamApi<Measurement>(`/tickets/inspections/${id}/measurement`)
    return isEmpty(data) ? undefined : data
  }

  const getFiles = async (id: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/tickets/inspections/${id}/files`)
    return data
  }

  const addFiles = async (id: string, files: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    files.forEach((file) => formData.append("files", file))

    const { data: newFiles } = await useSamApi<Array<FileData>>(
      `/tickets/inspections/${id}/files`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    return newFiles
  }

  const deleteFile = async (id: string, fileId: string): Promise<FileData> => {
    const { data: deletedFile } = await useSamApi<FileData>(`/tickets/inspections/${id}/files/${fileId}`, { method: "DELETE" })
    return deletedFile
  }

  const getInspectionActions = async (id: string) => {
    const { data } = await useSamApi<Array<Action>>(`/tickets/inspections/${id}/actions`)
    return data
  }

  const getInspectionHistoryCount = async (organization?: string) => {
    const searchParams = getUrlSearchParams({ organization })
    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/tickets/inspections/history-count?${searchParams}`)

    return data
  }

  const getValuesByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<InspectionValue>> => {
    const defaultFilter = {
      select: {
        "inspectionProperty.valueType": 1,
        isGeneralCondition: 1,
        "inspectionProperty.meterReadingSettings": 1,
        "inspectionProperty.hasMeterReading": 1,
        "inspection._id": 1,
      },
    }

    const combined = merge(defaultFilter, queryParameters)
    const query = getUrlSearchParams(combined)

    const { data } = await useSamApi<PaginateResult<InspectionValue>>(`/tickets/inspections/values?${query}`)
    return data
  }

  const getValueById = async (id: string) => {
    const { data } = await useSamApi<InspectionValue>(`/tickets/inspections/values/${id}`)
    return data
  }

  const getValueHistory = async (inspectionValue: InspectionValue) => {
    const queryParams = getUrlSearchParams({
      includeAll: true,
      pagination: false,
      query: JSON.stringify({
        "inspectionProperty._id": inspectionValue.inspectionProperty?._id ?? { $exists: false },
        "assetComponentGroup._id": inspectionValue.assetComponentGroup._id,
        "assetComponent._id": inspectionValue.assetComponent?._id ?? { $exists: false },
      }),
    })

    const { data } = await useSamApi<PaginateResult<InspectionValue>>(`/tickets/inspections/values?${queryParams}`)
    return data.docs
  }

  const getValuesByInspection = async (inspectionId: string) => {
    const { data } = await useSamApi<Array<InspectionValue>>(`/tickets/inspections/${inspectionId}/values`)
    return data
  }

  const updateValue = async (inspectionId: string, valueId: string, value: UpdateInspectionValuePayload) => {
    const { data } = await useSamApi<InspectionValue>(`/tickets/inspections/${inspectionId}/values/${valueId}`, {
      method: "PATCH",
      body: JSON.stringify(value),
    })
    return data
  }

  const getValueFiles = async (id: string) => {
    const { data } = await useSamApi<InspectionPropertyFilesResponse>(`/tickets/inspections/${id}/property-files`)
    return data
  }

  const addFilesToValue = async (inspectionId: string, valueId: string, files: Array<File>) => {
    const formData = new FormData()
    files.forEach((file) => formData.append("files", file))

    const { data } = await useSamApi<Array<FileData>>(
      `/tickets/inspections/${inspectionId}/values/${valueId}/files`,
      { method: "POST", body: formData },
      { forceNoContentType: true },
    )
    return data
  }

  const removeFileFromValue = async (inspectionId: string, valueId: string, fileId: string) => {
    const { data } = await useSamApi<FileData>(`/tickets/inspections/${inspectionId}/values/${valueId}/files/${fileId}`, {
      method: "DELETE",
    })
    return data
  }

  const migrateInspections = async (organizationId: string) => {
    await useSamApi(`/tickets/inspections/migrate/${organizationId}`, { method: "POST" })
  }

  const fixConditionReferencesMigration = async (organizationId: string) => {
    await useSamApi(`/tickets/inspections/migrate/${organizationId}/fix-condition-references`, { method: "POST" })
  }

  return {
    inspections,
    listInspections,
    types,

    createInspection,
    getInspections,
    getInspectionById,
    getInspectionList,
    getInspectionListByAssetId,
    deleteInspection,
    updateInspection,
    updateStatus,
    getTypes,
    getMeasurement,
    getInspectionActions,

    getFiles,
    addFiles,
    deleteFile,

    getInspectionHistoryCount,

    getValueById,
    getValuesByPage,
    getValueHistory,
    getValuesByInspection,
    updateValue,

    getValueFiles,
    addFilesToValue,
    removeFileFromValue,

    migrateInspections,
    fixConditionReferencesMigration,
  }
})
