import merge from "lodash-es/merge"
import * as Sentry from "@sentry/vue"

type RequestOptions = {
  forceNoRenew?: boolean
  forceNoContentType?: boolean
}

export async function useSamApi<Res = undefined>(
  apiEndpoint: string,
  init?: RequestInit,
  options?: RequestOptions,
): Promise<{ data: Res; response: Response; responseStatus: number }> {
  const authStore = useAuthStore()
  const { token, expiresAt } = storeToRefs(authStore)

  const runtimeConfig = useRuntimeConfig()

  const nowEpochInSeconds = new Date().getTime() / 1000
  if (!options?.forceNoRenew && expiresAt.value < nowEpochInSeconds) {
    console.warn("Token expired, renewing")
    const currentUser = await getCurrentUser()
    if (!currentUser) {
      console.error("No user found")
    }

    await authStore.renewFirebaseToken(currentUser).catch(() => authStore.logout())
  }

  const defaultInit: RequestInit = options?.forceNoContentType
    ? {
        headers: {
          Authorization: `Bearer ${token.value}`,
          Accept: "application/json",
        },
      }
    : {
        headers: {
          Authorization: `Bearer ${token.value}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }

  const mergedInit = merge(defaultInit, init)
  const res = await fetch(runtimeConfig.public.BACKEND_URL + apiEndpoint, mergedInit)

  if (res.status >= 400) {
    const responseBody = await res.json().catch(() => ({}))

    if (res.status === 401) {
      authStore.logout()
    }

    const errorMessage = responseBody?.message
      ? `${responseBody?.status || res.status} ${responseBody?.message}`
      : `${res.status} ${res.statusText}`

    const mainStore = useMainStore()
    mainStore.notify({
      title: "Error",
      text: `${responseBody?.scopes ?? ""} ${errorMessage}`.trim(),
      type: "error",
      closable: true,
      timeout: 5000,
    })

    if (responseBody?.scopes) {
      Sentry.captureException(new AuthError(errorMessage, responseBody.scopes))
      return Promise.reject(new AuthError(errorMessage, responseBody.scopes))
    }

    Sentry.captureException(new Error(errorMessage))
    return Promise.reject(new Error(errorMessage))
  }

  let data = {} as Res

  try {
    if (res.status !== 204) {
      const headers = mergedInit.headers
      const contentType = headers && "Accept" in headers ? headers["Accept"] : undefined

      if (contentType === "application/json") {
        data = await res.json()
      } else if (contentType === "text/html" || contentType === "text/plain") {
        data = (await res.text()) as unknown as Res
      } else {
        data = (await res.blob()) as unknown as Res
      }
    } else {
      data = null as Res
    }
  } catch (e) {
    console.error(e)
  }

  return {
    data,
    response: res,
    responseStatus: res.status,
  }
}
