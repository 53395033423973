export type FileType = "malfunction" | "action" | "asset" | "inspection"

export type UploadedByData = {
  user: { _id: string; description: string }
  organization: { _id: string; description: string }
}
export interface FileData {
  _id: string
  organization: string
  description: string
  extension: string
  general: boolean
  private: boolean
  reference: string
  size: number
  angle: number // 0, 90, 180, 240
  type: "IMAGE" | "FILE"
  remark: string
  createdAt: string
  updatedAt: string
  uploadedBy: UploadedByData
}

export interface StorageSpaceResponse {
  storageSpaceInBytes: number
  totalStorageSpaceInBytes: number
}

export const useFileStore = defineStore("file", () => {
  const getFileById = async (id: string) => {
    const { data } = await useSamApi<FileData>(`/files/${id}`)
    return data
  }

  const getFileUrl = async (id?: string, defaultFile?: string, thumb = false) => {
    if (!id) {
      if (!defaultFile) {
        throw new Error("no default image")
      }
      return defaultFile!
    }

    try {
      const { data: imageBlob } = await useSamApi<Blob>(`/files/${id}/view?thumbnail=${thumb}`, { headers: { Accept: "*/*" } })

      return URL.createObjectURL(imageBlob)
    } catch (e) {
      if (!defaultFile) {
        throw e
      }

      return defaultFile
    }
  }

  const createFiles = async (files: Array<File>): Promise<Array<FileData>> => {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append("files", file)
    })

    const { data } = await useSamApi<Array<FileData>>(
      `/files`,
      {
        method: "POST",
        body: formData,
      },
      { forceNoContentType: true },
    )

    return data
  }

  const updateFile = async (id: string, fileData: Partial<FileData>): Promise<FileData> => {
    const { data } = await useSamApi<FileData>(`/files/${id}`, { method: "PUT", body: JSON.stringify(fileData) })
    return data
  }

  const deleteFile = async (fileId: string, reference?: FileType, objectId?: string): Promise<FileData> => {
    const { data } = await useSamApi<FileData>(`/files/${fileId}${objectId && reference ? `/${reference}/${objectId}` : ""}`, {
      method: "DELETE",
    })
    return data
  }

  const countFiles = async (organizationId?: string, createdFrom?: Date): Promise<number> => {
    const queryParameters = new URLSearchParams()
    if (organizationId) {
      queryParameters.append("organization", organizationId)
    }
    if (createdFrom) {
      queryParameters.append("createdFrom", createdFrom.toISOString())
    }

    const { data } = await useSamApi<{ count: number }>(`/files/count?${queryParameters}`)
    return data.count
  }

  const getStorageSpace = async (organizationId?: string): Promise<StorageSpaceResponse> => {
    const { data } = await useSamApi<StorageSpaceResponse>(`/files/storage-space${organizationId ? `?organization=${organizationId}` : ""}`)
    return data
  }

  const getFileHistoryCount = async (organization?: string) => {
    const searchParams = getUrlSearchParams({ organization })
    const { data } = await useSamApi<Array<HistoryDataPoint>>(`/files/history-count?${searchParams}`)

    return data
  }

  return {
    getFileById,
    getFileUrl,
    createFiles,
    updateFile,
    deleteFile,

    countFiles,
    getStorageSpace,
    getFileHistoryCount,
  }
})
