import type { PaginateResult } from "mongoose"
import merge from "lodash-es/merge"

export enum ListValueType {
  STREET = "STREET",
  CITY = "CITY",
  DISTRICT = "DISTRICT",
  AREA = "AREA",
  MUNICIPALITY = "MUNICIPALITY",
  INSPECTION_DESCRIPTION = "INSPECTION_DESCRIPTION",
  ACTION_DESCRIPTION = "ACTION_DESCRIPTION",
  MALFUNCTION_DESCRIPTION = "MALFUNCTION_DESCRIPTION",
  ARTICLE_CATEGORY = "ARTICLE_CATEGORY",
  INVOICE_PERIOD = "INVOICE_PERIOD",
}

export interface ListValue {
  _id: string
  organization: string
  type: ListValueType
  description: string
}

const createListStore = (type: ListValueType) =>
  defineStore(`list:${type}`, () => {
    /** @deprecated */
    const items = ref<Array<ListValue>>([])

    const getItems = async (pagination = false, organization?: string) =>
      useCrudMethods<ListValue>("/list-values", items).readItems({
        query: JSON.stringify({ type, organization }),
        sort: JSON.stringify({ description: 1 }),
        pagination,
      })
    const getItemsById = async (id: ListValue["_id"]) => useCrudMethods<ListValue>("/list-values", items).readItemById(id)
    const updateItem = async (id: ListValue["_id"], item: Partial<ListValue>) =>
      useCrudMethods<ListValue>("/list-values", items).updateItem(id, item)
    const postItem = async (item: Partial<ListValue>) => useCrudMethods<ListValue>("/list-values", items).createItem(item)
    const deleteItem = async (id: ListValue["_id"]) => useCrudMethods<ListValue>("/list-values", items).deleteItem(id)

    const getByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<ListValue>> => {
      const defaultFilter = {
        query: {
          type,
        },
      }

      const combined = merge(defaultFilter, queryParameters)
      const query = getUrlSearchParams(combined)

      const { data } = await useSamApi<PaginateResult<ListValue>>(`/list-values?${query}`)
      return data
    }

    return {
      items,
      getItems,
      getItemsById,
      getByPage,
      updateItem,
      postItem,
      deleteItem,
    }
  })

export const useStreetStore = createListStore(ListValueType.STREET)
export const useCityStore = createListStore(ListValueType.CITY)
export const useDistrictStore = createListStore(ListValueType.DISTRICT)
export const useAreaStore = createListStore(ListValueType.AREA)
export const useMunicipalityStore = createListStore(ListValueType.MUNICIPALITY)
export const useArticleCategoryStore = createListStore(ListValueType.ARTICLE_CATEGORY)
export const useMalfunctionDescriptionStore = createListStore(ListValueType.MALFUNCTION_DESCRIPTION)
export const useInspectionDescriptionStore = createListStore(ListValueType.INSPECTION_DESCRIPTION)
export const useActionDescriptionStore = createListStore(ListValueType.ACTION_DESCRIPTION)
export const useInvoicePeriodStore = createListStore(ListValueType.INVOICE_PERIOD)
